import revive_payload_client_r0FuA5FSHo from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_gmvc4ro6szunv7dfts7svu5i2y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CGS4H9yFM5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_gmvc4ro6szunv7dfts7svu5i2y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Bsv9yb2yQs from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_gmvc4ro6szunv7dfts7svu5i2y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_zgIluvAewZ from "/vercel/path0/node_modules/.pnpm/nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.4_rollup@4.18.0_vue@3.4.27_typescript@5.6.2_/node_modules/nuxt-sentry/dist/runtime/sentry.client.mjs";
import supabase_client_N9FlusdQA7 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import check_outdated_build_client_BvLMgFc7gi from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_gmvc4ro6szunv7dfts7svu5i2y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_945NmqvNDC from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.6.2_vue@3.4.27_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/fastchange/.nuxt/components.plugin.mjs";
import prefetch_client_9IsxQjW2Mn from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_gmvc4ro6szunv7dfts7svu5i2y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JT2ZWazAMG from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+_jph3ifr2qdjqda2nc3zcgw2nxe/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t2YsVPk26V from "/vercel/path0/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.3.3_magicast@0.3.4_rollup@4.18.0_webpack@5.91.0_esbuild@0.21.5_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/fastchange/.nuxt/unocss.mjs";
import slideovers_jQd1IV71p6 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.3_rollup@4.18.0_vite@5.3.1_@types+_re53xeceu7servtxpa2cojbpxe/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_KtrljLe0ql from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.3_rollup@4.18.0_vite@5.3.1_@types+_re53xeceu7servtxpa2cojbpxe/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_v9NM0oFghF from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.16.0_focus-trap@7.5.4_magicast@0.3.4_qrcode@1.5.3_rollup@4.18.0_vite@5.3.1_@types+_re53xeceu7servtxpa2cojbpxe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_6q8sSv1VyT from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_f6yEieifnr from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_gmvc4ro6szunv7dfts7svu5i2y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_XIZCskOXXg from "/vercel/path0/layers/base/plugins/auto-animate.ts";
import chart_client_bcfhrmN7hg from "/vercel/path0/layers/base/plugins/chart.client.ts";
import use_head_sEf1F6ycmZ from "/vercel/path0/layers/base/plugins/use-head.ts";
import bottomSheet_client_C55D02kKcz from "/vercel/path0/layers/components/fubex-components/plugins/bottomSheet.client.ts";
import validation_H6BESNSSPe from "/vercel/path0/layers/components/fubex-components/plugins/validation.ts";
import vue_toastification_client_OdKXnwPIgP from "/vercel/path0/layers/components/fubex-components/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_r0FuA5FSHo,
  unhead_CGS4H9yFM5,
  router_Bsv9yb2yQs,
  sentry_client_zgIluvAewZ,
  supabase_client_N9FlusdQA7,
  check_outdated_build_client_BvLMgFc7gi,
  plugin_vue3_945NmqvNDC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9IsxQjW2Mn,
  plugin_JT2ZWazAMG,
  plugin_t2YsVPk26V,
  unocss_MzCDxu9LMj,
  slideovers_jQd1IV71p6,
  modals_KtrljLe0ql,
  colors_v9NM0oFghF,
  plugin_client_6q8sSv1VyT,
  chunk_reload_client_f6yEieifnr,
  auto_animate_XIZCskOXXg,
  chart_client_bcfhrmN7hg,
  use_head_sEf1F6ycmZ,
  bottomSheet_client_C55D02kKcz,
  validation_H6BESNSSPe,
  vue_toastification_client_OdKXnwPIgP
]